.modal {
  position: fixed;
  height: 100vh;
  // width: 100vw;
  left: 0;
  z-index: 105;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -100%;
  opacity: 0;
  transition: all 0.3s;

  &.open {
    top: 0;
    opacity: 1;
  }

  &.close {
    top: -100%;
    opacity: 0;
  }

  .container {
    position: relative;
    z-index: 107;
    max-width: 95vw;
    max-height: 90vh;
    overflow: auto;
  }
}

.overlay {
  background-color: #1e3c3142;
  position: fixed;
  z-index: 102;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
}
