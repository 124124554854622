.container {
  &[data-view="vertical"] {
    flex-direction: column;
  }
  &[data-view="horizontal"] {
    flex-direction: row;
    justify-content: space-between;
  }
  .radio {
    svg {
      font-size: 1.2rem;
    }
    .active {
      color: var(--ono-primary);
    }
    .lable {
      font-size: 0.8rem;
      font-weight: 500;
      color: var(--ono-subtext);
      margin: 0px;
    }
  }
}
