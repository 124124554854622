.calanderContainer {
  .header {
    .navigation {
      width: 35px;
      height: 35px;
      text-align: center;
      cursor: pointer;
      background: white;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .weekdays {
    display: flex;
    justify-content: space-around;
    color: var(--grey-slate-800);
    font-weight: 600;
    font-size: 0.875rem;
  }

  .dates {
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    row-gap: 2px;
    margin-bottom: 10px;

    > * {
      flex: 0 0 14.285714285714%;
      text-align: center;

      > * {
        cursor: pointer;
        height: 2.2rem;
        width: 2.2rem;
        border-radius: 50%;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        color: var(--ono-subtext);
        font-weight: 600;
        transition: all 0.3s;

        &:hover {
          background-color: var(--ono-select-background);
        }
      }
    }

    .active > * {
      background-color: var(--ono-primary);
      color: white;
    }

    .fromdate > * {
      background-color: #d43939;
      color: white;
      display: inline-flex;
      flex-direction: column;

      &::before {
        content: "From";
        top: 6px;
        font-size: 10px;
      }
    }

    .todate > * {
      background-color: var(--ono-primary);
      color: white;
      display: inline-flex;
      flex-direction: column;

      &::before {
        content: "To";
        top: 6px;
        font-size: 10px;
      }
    }
  }
  .date {
    &[data-disable="true"] {
      cursor: not-allowed;
      color: #929292;
      background-color: white;
    }
  }
  .date[data-curdate="true"] {
    background-color: var(--ono-primary);
    color: white;
  }
  .focused {
    border: 1px solid var(--ono-select-background) !important;
  }
}
*[data-disable="true"] {
  cursor: not-allowed;
  color: #929292;
}
*[data-selected="true"] {
  background-color: var(--ono-primary);
  color: white;
  &:hover {
    background-color: var(--ono-primary);
  }
}
