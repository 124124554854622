.orderStatus {
  color: rgb(var(--rgb-primary));
  &::before {
    content: " ";
    height: 0.4rem;
    width: 0.4rem;
    display: inline-block;
    background-color: var(--ono-primary);
    border-radius: 50%;
    margin-right: 0.4rem;
  }
  &[data-status="ACCEPTED"] {
    border-color: rgb(var(--rgb-primary));
    color: rgb(var(--rgb-primary));
  }
  &[data-status="PENDING"] {
    border-color: #ff9900;
    color: #ff9900;
    &::before {
      background-color: #ff9900;
    }
  }

  &[data-status="UPDATE"] {
    border-color: #ff9900;
    color: #000;
    &::before {
      background-color: #ff9900;
    }
  }
  &[data-status="CANCEL"] {
    border-color: var(--ono-red);
    color: var(--ono-red);
    &::before {
      background-color: var(--ono-red);
    }
  }
  &[data-status="CANCELLED"] {
    border-color: var(--ono-red);
    color: var(--ono-red);
    &::before {
      background-color: var(--ono-red);
    }
  }
}
.disabledDiv {
  background-color: gray;
}

.cont {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}
.steps {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #ddd;
  &[data-status="active"] {
    background-color: "#4CAF50";
  }
  // background-color: ${props => props.active ? '#4CAF50' : '#ddd'};
}

.selectedIcon {
  position: absolute;
  top: -4px;
  right: 0;
  color: var(--ono-blue);
  z-index: 099;
  background-color: #fff;
  border-radius: 100%;
}
ul li {
  list-style: none;
}

.border_style::before {
  content: "";
  position: absolute;
  top: 29px;
  height: 1px;
  border-bottom: 1px solid #ddd;
  width: 100%;
}

.react-time-picker__wrapper {
  border: 1px solid red !important;
  padding: 10px !important;
  border-radius: 10px !important;
}

.react-time-picker__inputGroup__input {
  color: green !important;
}
.activeTab {
  color: var(--ono-primary);
  border-bottom: 3px solid var(--ono-primary);
}

.activeScroll {
  overflow-x: auto;
  // position: relative;
  // scrollbar-color: #16a02c #16a02c;
  // scrollbar-width: thin;
  // -ms-overflow-style: none;
  padding-bottom: 5px;
  white-space: nowrap;
}

// ::-webkit-scrollbar {
//   height: 3px;
//   width: 3px;
//   background: gray;
// }

// /* Track */
// ::-webkit-scrollbar-track {
//   background: #f1f1f1;
// }

// ::-webkit-scrollbar-thumb:horizontal {
//   background: var(--ono-primary);
//   border-radius: 10px;
// }
.table {
  .heading {
    font-size: 12px;
    font-weight: 600;
    border-bottom: 1px solid var(--ono-border-color);
    border-top-right-radius: 0.6rem;
    border-top-left-radius: 0.6rem;
  }
  margin-bottom: 20px;
  .tbody {
    > div {
      font-size: 12px;
      border-bottom: 1px solid #e5e7eb;
      border-left: 1px solid #e5e7eb;
      &:hover {
        // background-color: var(--ono-select-background);
      }

      &[data-selected="true"] {
        background-color: var(--ono-primary);
        // border-top-left-radius: 0.3rem;
        // border-top-right-radius: 0.3rem;
        color: white;
        .amount {
          color: white;
        }
      }
      &[data-selected="false"] {
        &[data-type="seller"] {
          .amount {
            color: var(--ono-red);
          }
        }
        &[data-type="buyer"] {
          .amount {
            color: var(--ono-primary);
          }
        }
      }

      > * {
        padding: 0.65rem;
      }
    }
    .borderedCol {
      > * {
        // height: 100%;
        border-right: 1px solid #e5e7eb;
      }
    }
  }
}
button:disabled {
  color: #ddd;
  cursor: initial;
  opacity: 0.5;
}
