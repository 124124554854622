.pageContainer {
  animation-name: slideUp;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-timing-function: ease;
}
@keyframes slideUp {
  0% {
    top: 50vh;
  }
  100% {
    top: 0;
  }
}
