.monthName {
  color: var(--ono-subtext);
  font-weight: 600;
  &[data-disable="true"] {
    cursor: not-allowed;
    color: #929292;
  }
}
.active {
  background-color: var(--ono-primary);
  color: white;
}
