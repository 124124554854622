.button {
  padding: 0.65rem 1.1rem;
  border-radius: 0.375rem;
  transition: all 0.5s;
  font-size: 0.875rem;
  font-weight: 600;

  &:active {
    transform: scale(0.98);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
}

.button[data-variant="contained"] {
  background: var(--ono-primary);
  color: var(--ono-white);

  &:hover {
    background: #2cac42;
    box-shadow: var(--primary-box-shadow);
  }
}

.button[data-variant="transparent"] {
  background: transparent;
  color: var(--ono-primary);

  &:hover {
    background: var(--ono-select-background);
    box-shadow: var(--primary-box-shadow);
  }
}
.button[disabled] {
  cursor: not-allowed;
  background-color: rgb(var(--rgb-primary), 0.5);
  &:hover {
    background-color: rgb(var(--rgb-primary), 0.5);
  }
}
.button[data-variant="red"] {
  background: #f3d7d7;
}
.button[data-variant="darkred"] {
  background: #d43939;
  color: #fff;
}
.button[data-shape="round"] {
  border-radius: 9999px;
}
.button[data-variant="primaryLight"] {
  background-color: var(--ono-select-background);
  color: #2cac42;
  border: var(--ono-primary);
}
