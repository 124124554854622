.icon {
  border: 1px solid;
  padding: 0.2rem;
  font-size: 1.4rem;
  border-color: #b9a9a9;
}
.dateContainer {
  width: 6rem;
  transform: translateX(0%);
  transition: transform 0.3s ease 0s;
  > * {
    flex-shrink: 0;
    width: 100%;
  }
}
