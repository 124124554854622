.popover {
  position: fixed;
  border: 1px solid var(--ono-border-primary);
  background: white;
  border-radius: 0.3rem;
  z-index: 110;
  // max-height: 400px;
  // overflow-y: auto;

  // &::before {
  //   content: "";
  //   height: 1rem;
  //   width: 1rem;
  //   position: absolute;
  //   left: calc(50% - 0.5rem);
  //   transform: rotate(45deg);
  //   top: -0.5rem;
  //   background: white;
  //   border-left: 1px solid var(--ono-border-color);
  //   border-top: 1px solid var(--ono-border-color);
  // }
  &[data-open="true"] {
    display: block;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
  }
  &[data-animation="sliderleftToRight"] {
    animation-name: sliderleftToRight;
    ::before {
      border: 15px solid;
      border-color: #0000 #0000 #fff;
      z-index: 1;
      content: "";
      left: 78%;
      position: absolute;
      top: -23px;
    }
  }
  &[data-animation="sliderDown"] {
    animation-name: sliderDown;
  }
  &[data-animation="sliderUp"] {
    animation-name: sliderUp;
  }
  &[data-animation="sliderRightToLeft"] {
    animation-name: sliderRightToLeft;
    ::before {
      border: 15px solid;
      border-color: #0000 #0000 #fff;
      z-index: 1;
      content: "";
      left: 78%;
      position: absolute;
      top: -23px;
    }
  }
}
.popoverOverlay {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 109;
  display: none;
  &[data-open="true"] {
    display: block;
  }
}
@keyframes sliderDown {
  0% {
    opacity: 0;
    transform: translateY(-3rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes sliderUp {
  0% {
    opacity: 0;
    transform: translateY(3rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes sliderleftToRight {
  0% {
    opacity: 0;
    transform: translateX(-3rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes sliderRightToLeft {
  0% {
    opacity: 0;
    transform: translateX(3rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@media only screen and (min-width: 800px) and (max-width: 1300px) {
  .popover {
    &[data-animation="sliderDown"] {
      top: 3.8rem !important;
    }
  }
}
