.activeTab {
  color: var(--ono-yellow);
  //   border-bottom: 3px solid var(--ono-primary);
}
.colorwhite {
  color: #fff;
}

@media only screen and (min-width: 800px) and (max-width: 1300px) {
  .menubar {
    padding: 0.3rem !important;
  }
}
