/* Container to handle overflow */
.tablecontainer {
  width: 100%;
  overflow-x: auto; /* Horizontal scroll */
}

table {
  width: 100%;
  table-layout: fixed;
}

thead th {
  position: sticky; /* Sticky header */
  top: 0;
  background-color: var(--ono-select-background);
  text-align: left;
  font-weight: 600;
  padding: 5px 10px;
  z-index: 9;
}

tbody td {
  padding: 5px 10px;
  border: 1px solid #e5e7eb;
  background-color: #fff;
}

/* Scrollbar styling for better mobile experience */
.table-container::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.table-container::-webkit-scrollbar-thumb {
  background-color: #bc1818;
  border-radius: 10px;
}
.imageNoData {
  max-width: none !important;
}
.imageNoDiv {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
}
.reports {
  padding: 20px;
  padding-top: 0px;
  overflow: hidden;
}

.activeTab {
  color: var(--ono-primary);
  border-bottom: 3px solid var(--ono-primary);
}
@media (max-width: 600px) {
  //   .tablecontainer {
  //     height: calc(100vh - 29rem) !important;
  //   }
}
